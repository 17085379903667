import {
  Button,
  ButtonGroup,
  Divider,
  Fab,
  Grid,
  Paper,
  Typography
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

import {
  json,
  useLoaderData, useRevalidator,
} from "react-router-dom";

import {
  useEffect,
  useState,
} from "react";

import {
  parseWeekDays, parseHourTags, Header,
  CampaignProperty, parseCountryNames, formatDate, formatBudget
} from "../../utils/settingsTabHelper";

import OptimizationDetails from "./OptimizationDetails";
import OptimizationEditForm from "./OptimizationEditForm";
import { OptimizationChangesProvider } from "./OptimizationsContext";

import NewOptimizationModal from "./components/NewOptimizationModal";

import { get } from "../../apiClient";
import { title } from "radash";

export const loader = async ({ params, request }) => {
  try {
    const campaign = await get("managed_plus", `/campaigns/${params.id}`, {
      signal: request.signal,
    });
    return json(campaign);
  } catch (error) {
    switch (error.status) {
      case 404:
        throw new json(
          {
            title: "Campaign not found",
            text: `The Campaign with ID ${params.id} could not be found.`,
          },
          { status: 404 }
        );
      case 422:
        throw new json(
          {
            title: "Invalid campaign ID",
            text: `The campaign ID ${params.id} is not valid.`,
          },
          { status: 422 }
        );
      default:
        throw error;
    }
  }
}

const SettingsTab = () => {
  const [isEditing, setEditing] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const { optimizations, ...campaignData } = useLoaderData();
  const [campaignStatus, setCampaignStatus] = useState(title(campaignData.campaign_state));
  const revalidate = useRevalidator();

  useEffect(() => {
    revalidate.revalidate();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCampaignStatus(title(campaignData.campaign_state));
  }, [campaignData]);

  const handleHide = (pipedFunction) => {
    pipedFunction();
    setEditing(false)
  };

  return (
    <OptimizationChangesProvider optimizations={optimizations}>
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <ButtonGroup>
            {isEditing ? (
              <Typography component="label" variant="button" sx={{ color: "text.secondary" }}>
                <Fab
                  color="primary1"
                  size="small"
                  aria-label="Add Optimization"
                  sx={{ mr: 1, color: "common.white", height: 35 }}
                  onClick={(_e) => setModalOpen(true)}
                >
                  <AddIcon />
                </Fab>
                 ADD OPTIMIZATION
              </Typography>
            ) : (
              <Button
                startIcon={<EditIcon sx={{ color: "primary1.main" }}/>}
                variant="text"
                sx={{ color: "text.secondary" }}
                onClick={(_event) => setEditing(true)}
              >
                EDIT OPTIMIZATION
              </Button>
            )}
          </ButtonGroup>
        </Grid>
        <Grid item xs={6}>
          <Paper sx={{ px: 3, py: 2 }} style={{backgroundColor: "#f5f5f5"}} elevation={3}>
            <Header value={"Setup"}/>
            <Divider sx={{ my: 1 }} />
            <Grid container spacing={2}>
              <CampaignProperty name="Company Name" value={<a href={`/admin/managed_plus_accounts/${campaignData.company_id}`}>{campaignData.company_name}</a>}
                />
              <CampaignProperty
                name="Publisher Campaign ID"
                value={<a href={`/admin/campaigns/${campaignData.publisher_campaign_id}`}>{campaignData.publisher_campaign_id}</a>}
              />
              <CampaignProperty
                name="Campaign Name"
                value={campaignData.name}
                xs={12} />
              <CampaignProperty
                name="Offer ID"
                value={campaignData.offer_id} />
              <CampaignProperty
                name="Campaign Status"
                value={campaignStatus}
                />
            </Grid>
          </Paper>
          <Grid item xs={6}>&nbsp;</Grid>
          <Grid item>
            <Paper sx={{ px: 3, py: 2 }} style={{backgroundColor: "#f5f5f5"}} elevation={3}>
              <Header value={"Performance"}/>
              <Divider sx={{ my: 1 }} />
              <Grid container spacing={2}>
                <CampaignProperty name="Impressions" value={ campaignData.performance_summary.impressions.toLocaleString("en-US") ?? 0 } />
                <CampaignProperty
                  name="Spend"
                  value={formatBudget(campaignData.performance_summary.spend) ?? "$0.00"} />
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={6}>&nbsp;</Grid>
          <Grid item>
            <Paper sx={{ px: 3, py: 2 }} style={{backgroundColor: "#f5f5f5"}} elevation={3}>
              <Header value={"Pacing"}/>
              <Divider sx={{ my: 1 }} />
              <Grid container spacing={2}>
                <CampaignProperty name="Start Date" value={formatDate(campaignData.start_date)} />
                <CampaignProperty
                  name="Lifetime Budget"
                  value={formatBudget(campaignData.lifetime_budget_cap) ?? "N/A"} />
                <CampaignProperty
                  name="End Date"
                  value={formatDate(campaignData.end_date)} />
                <CampaignProperty name="Daily Budget" value={formatBudget(campaignData.daily_budget_cap) ?? "N/A"} />
                <CampaignProperty
                  name="Hours"
                  value={parseHourTags(campaignData.hours)}/>
                <CampaignProperty
                  name="Max Bid per Step"
                  value={formatBudget(campaignData.max_bid, { maximumFractionDigits: 6, minimumFractionDigits: 6 }) ?? "N/A"} />
                <CampaignProperty
                  name="Day of the Week"
                  value={parseWeekDays(campaignData.days)}
                  xs={12} />
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={6}>&nbsp;</Grid>
          <Paper sx={{ px: 3, py: 2 }} style={{backgroundColor: "#f5f5f5"}} elevation={3}>
            <Header value={"Targeting"} />
            <Divider sx={{ my: 1 }} />
            <Grid container spacing={2}>
              <CampaignProperty name="Platform" value={campaignData.platform_targeting_types.join(", ")} />
              <CampaignProperty
                name="Gender"
                value={campaignData.targeted_gender_display_name}/>
              <CampaignProperty
                name="Country"
                value={parseCountryNames(campaignData.targeted_country)}/>
              <CampaignProperty name="Age" value={campaignData.targeted_age_display_name} />
              <CampaignProperty
                name="State"
                value={campaignData.targeted_states.join(", ") !== "" ? campaignData.targeted_states.join(", ") : "N/A"}
                xs={12}/>
              <CampaignProperty
                name="DMA"
                value={campaignData.targeted_dma_codes.join(", ") !== "" ? campaignData.targeted_dma_codes.join(", ") : "N/A"}
                xs={12}/>
              <CampaignProperty
                name="Postal Code"
                value={campaignData.targeted_zip_codes.join(", ") !== "" ? campaignData.targeted_zip_codes.join(", ") : "N/A"}
                xs={12} />
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          {isEditing ? <OptimizationEditForm onHide={handleHide} /> : <OptimizationDetails />}
        </Grid>
        {isModalOpen && <NewOptimizationModal onClose={() => setModalOpen(false)} />}
      </Grid>
    </OptimizationChangesProvider>
  );
};

export default SettingsTab;
